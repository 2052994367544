import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';

const NoResultsMessage = ({ className, ...props }) => (
  <div className={`${styles.message} ${className}`} {...props} />
);

NoResultsMessage.defaultProps = {
  className: '',
};

NoResultsMessage.propTypes = {
  className: PropTypes.string,
};

export default NoResultsMessage;
