import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import styles from './styles.module.css';

const CardImage = ({ className, ...props }) => (
  <Img className={`${styles.image} ${className}`} {...props} />
);

CardImage.defaultProps = {
  className: '',
};

CardImage.propTypes = {
  className: PropTypes.string,
};

export default CardImage;
