import React from 'react';
import DarkButton from '../../../elements/DarkButton';
import ChevronDownIcon from '../../../elements/Icons/ChevronDownIcon';
import styles from './styles.module.css';

const ShowMoreButton = ({ className, ...props }) => (
  <div className={styles.wrapper}>
    <DarkButton className={`${styles.showMoreButton} ${className}`} {...props}>
      <div className={styles.inner}>
        <span>Show More</span>
        <ChevronDownIcon className={styles.icon} />
      </div>
    </DarkButton>
  </div>
);

export default ShowMoreButton;
