/* eslint react/destructuring-assignment: 0 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import debounce from '../../../utils/debounce';
import Input from '../../../elements/Input';
import styles from './styles.module.css';
import CancelIcon from '../../../elements/Icons/CancelIcon';

class FreeTextSearch extends Component {
  constructor(props) {
    super(props);

    const { value, onChange, delay } = this.props;
    this.state = { value };

    this.handleChange = this.handleChange.bind(this);
    this.handleSearchDebounced = debounce(() => onChange.apply(this, [this.state.value]), delay);
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
    this.handleSearchDebounced(event.target.value);
  }

  render() {
    const { value } = this.props;
    return (
      <div className={styles.wrapper}>
        <Input
          className={styles.searchBox}
          placeholder="Search"
          type="search"
          value={this.state.value}
          onChange={this.handleChange}
        />
        {value && (
          <button type="button" className={styles.cancelButton} onClick={this.props.resetSearch}>
            <CancelIcon className={styles.cancelIcon} />
          </button>
        )}
      </div>
    );
  }
}

FreeTextSearch.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  resetSearch: PropTypes.func.isRequired,
  delay: PropTypes.number,
};

FreeTextSearch.defaultProps = {
  delay: 200,
};

export default FreeTextSearch;
