import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';
import CallToAction from '../../core/CallToAction';
import DarkButton from '../../../elements/DarkButton';

const ResetFilterButton = ({ className, ...props }) => (
  <CallToAction
    className={`${styles.cta} ${className}`}
    as={DarkButton}
    text="Reset filter"
    {...props}
  />
);

ResetFilterButton.defaultProps = {
  className: '',
};

ResetFilterButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export default ResetFilterButton;
