import React from 'react';
import PropTypes from 'prop-types';
import LocationFilterDropdown from '../LocationFilterDropdown';
import ResetFilterButton from '../ResetFilterButton';
import MapModal from '../MapModal';
import styles from './styles.module.css';
import DarkButton from '../../../elements/DarkButton';
import cases from '../../../../data/cases/cases.json';
import MapIcon from '../../../elements/Icons/MapIcon';

const LocationFilter = ({
  selectedLocationId,
  setLocationId,
  showMap,
  resetFilter,
  showGalleryMap,
  hideGalleryMap,
}) => (
  <div className={styles.wrapper}>
    <DarkButton className={styles.mapCta} onClick={() => showGalleryMap()}>
      <span style={{ whiteSpace: 'nowrap' }}>Show gallery map</span>
      <MapIcon />
    </DarkButton>
    <LocationFilterDropdown
      cases={cases}
      selectedLocationId={selectedLocationId}
      setLocationId={setLocationId}
    />
    <MapModal
      cases={cases}
      show={showMap}
      onHideMap={() => hideGalleryMap()}
      selectedLocationId={selectedLocationId}
      setLocationId={(id) => {
        setLocationId(id);
        hideGalleryMap();
      }}
    />
    {selectedLocationId ? <ResetFilterButton onClick={() => resetFilter()} /> : null}
  </div>
);

LocationFilter.defaultProps = {
  selectedLocationId: null,
};

LocationFilter.propTypes = {
  selectedLocationId: PropTypes.string,
  setLocationId: PropTypes.func.isRequired,
  showMap: PropTypes.bool.isRequired,
  resetFilter: PropTypes.func.isRequired,
  showGalleryMap: PropTypes.func.isRequired,
  hideGalleryMap: PropTypes.func.isRequired,
};

export default LocationFilter;
