/* eslint react/destructuring-assignment: 0 */
/* eslint react/forbid-prop-types: 0 */
/* eslint react/no-array-index-key: 0 */

import React, { Component } from 'react';
import chunk from 'lodash/chunk';
import PropTypes from 'prop-types';
import ObjectCard from '../../core/ObjectCard';
import CardRow from '../../core/CardRow';
import ShowMoreButton from '../ShowMoreButton';
import NoResultsMessage from '../NoResultsMessage';
import SearchResultsMessage from '../SearchResultsMessage';

class SearchResults extends Component {
  componentDidMount() {
    this.props.setCollection();
  }

  render() {
    if (this.props.results.length <= 0) {
      return (
        <NoResultsMessage>
          Your search for &apos;
          {this.props.queryText}
          &apos; returned 0 results
        </NoResultsMessage>
      );
    }

    return (
      <div>
        <SearchResultsMessage
          totalResults={this.props.results.length}
          totalShowing={this.props.limit}
        />
        <div>
          {chunk(this.props.results.slice(0, this.props.limit), 3).map((c, i) => (
            <CardRow key={`chunk-${i}`}>
              {c.map(obj => (
                <ObjectCard
                  key={obj.prn}
                  object={obj}
                  imagePlaceholder={this.props.imagePlaceholder}
                />
              ))}
            </CardRow>
          ))}
        </div>
        {(() => !(this.props.results.length > this.props.limit) || (
        <ShowMoreButton onClick={this.props.onShowMore} />
        ))()}
      </div>
    );
  }
}

SearchResults.propTypes = {
  limit: PropTypes.number.isRequired,
  queryText: PropTypes.string.isRequired,
  setCollection: PropTypes.func.isRequired,
  onShowMore: PropTypes.func.isRequired,
  results: PropTypes.arrayOf(PropTypes.object).isRequired,
  imagePlaceholder: PropTypes.object.isRequired,
};

export default SearchResults;
