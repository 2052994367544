import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';

const CardTitle = ({ className, children, ...props }) => (
  <h3 className={`${styles.title} ${className}`} {...props}>
    {children}
  </h3>
);

CardTitle.defaultProps = {
  className: '',
};

CardTitle.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default CardTitle;
