import { connect } from 'react-redux';
import FreeTextSearch from '../components/collection/FreeTextSearch';
import setQueryText from '../state/actionCreators/setQueryText';
import resetSearch from '../state/actionCreators/resetSearch';

const mapStateToProps = (state) => {
  const { queryText, searchKey } = state.search;
  return {
    value: queryText,
    key: searchKey,
  };
};

const mapDispatchToProps = dispatch => ({
  onChange: queryText => dispatch(setQueryText(queryText)),
  resetSearch: () => dispatch(resetSearch()),
});

const FreeTextSearchContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(FreeTextSearch);
export default FreeTextSearchContainer;
