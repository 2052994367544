import React from 'react';
import SVG from '../SVG';
import styles from './styles.module.css';

const MapIcon = ({ className = '', ...props }) => (
  <SVG className={`${styles.icon} ${className}`} viewBox="0 0 185.20833 132.29167" {...props}>
    <g transform="translate(7.9375 -172.65)" fill="none" stroke="#fff">
      <path
        d="m2.6458 199.1 41.01-15.875 41.01 15.875 41.01-15.875 41.01 15.875v95.25l-41.01-15.875-41.01 15.875-41.01-15.875-41.01 15.875z"
        strokeWidth="13.229"
      />
      <g strokeWidth="7.9375">
        <path d="m43.656 183.23v95.25" />
        <path d="m84.667 199.1v95.25" />
        <path d="m125.68 183.23v95.25" />
      </g>
    </g>
  </SVG>
);

export default MapIcon;
