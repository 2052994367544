/* eslint react/destructuring-assignment: 0 */

import React, { Component } from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';
import ButtonDark from '../../../elements/DarkButton';
import CallToAction from '../../core/CallToAction';
import GalleryMapBackground from '../GallerySVGMap/Background';
import GalleryMapCases from '../GallerySVGMap/Cases';
import styles from './styles.module.css';

ReactModal.setAppElement('#___gatsby');

class MapModal extends Component {
  constructor(props) {
    super(props);

    this.state = { hover: null };

    this.onHoverLocation = this.onHoverLocation.bind(this);
    this.onHoverLocationEnd = this.onHoverLocationEnd.bind(this);
    this.onClickCase = this.onClickCase.bind(this);
  }

  onHoverLocation(id) {
    this.setState({
      hover: this.props.cases.find(c => c.id === id),
    });
  }

  onHoverLocationEnd() {
    this.setState({ hover: null });
  }

  onClickCase() {
    if (this.state.hover) {
      this.props.setLocationId(this.state.hover.id);
    }
  }

  modalTitle() {
    if (!this.state.hover) return 'Choose a case';

    const gallery = this.state.hover.gallery ? `Gallery ${this.state.hover.gallery}` : '';
    const caseNum = this.state.hover.case ? ` Case ${this.state.hover.case}` : '';
    const title = this.state.hover.title ? `: ${this.state.hover.title}` : '';

    return gallery + caseNum + title;
  }

  render() {
    return (
      <ReactModal
        className={styles.modal}
        overlayClassName={styles.modalOverlay}
        isOpen={this.props.show}
      >
        <div className={styles.header}>
          <h3>{this.modalTitle()}</h3>
          <p>Click on a case in the map to explore the objects in that case.</p>
        </div>
        <GalleryMapBackground>
          <GalleryMapCases
            selectedLocationId={this.props.selectedLocationId}
            onHoverLocation={this.onHoverLocation}
            onHoverLocationEnd={this.onHoverLocationEnd}
            onClickLocation={this.onClickCase}
          />
        </GalleryMapBackground>
        <CallToAction as={ButtonDark} text="Close" onClick={this.props.onHideMap} />
      </ReactModal>
    );
  }
}

MapModal.defaultProps = {
  selectedLocationId: null,
};

MapModal.propTypes = {
  selectedLocationId: PropTypes.string,
  setLocationId: PropTypes.func.isRequired,
  cases: PropTypes.arrayOf(PropTypes.object).isRequired,
  show: PropTypes.bool.isRequired,
  onHideMap: PropTypes.func.isRequired,
};

export default MapModal;
