import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';

const hoverable = (selectedLocationId, onHoverLocation, onHoverLocationEnd) => ({
  locationId,
  as: T,
  ...props
}) => (
  <T
    onMouseEnter={() => onHoverLocation(locationId)}
    onMouseLeave={() => onHoverLocationEnd(locationId)}
    className={selectedLocationId === locationId ? styles.selected : ''}
    {...props}
  />
);

const Cases = ({
  selectedLocationId, onHoverLocation, onHoverLocationEnd, onClickLocation,
}) => {
  const SelectableCase = hoverable(selectedLocationId, onHoverLocation, onHoverLocationEnd);

  return (
    <g
      className={styles.cases}
      transform="translate(-90.299 -14.708)"
      fill="#bfbfbf"
      stroke="#000"
      strokeWidth=".3"
      onClick={onClickLocation}
    >
      <SelectableCase
        locationId="23"
        as="rect"
        transform="translate(-35.778 -99.854)"
        x="155.78"
        y="156.14"
        width="18.375"
        height="5.1115"
      />
      <SelectableCase
        locationId="24"
        as="rect"
        x="138.38"
        y="56.29"
        width="5.9802"
        height="5.1115"
      />
      <SelectableCase
        locationId="25"
        as="rect"
        transform="translate(-35.778 -99.854)"
        x="180.14"
        y="156.14"
        width="12.896"
        height="5.1115"
      />
      <SelectableCase
        locationId="26"
        as="rect"
        x="169.86"
        y="56.29"
        width="13.871"
        height="5.1115"
      />
      <SelectableCase
        locationId="27"
        as="rect"
        x="196.41"
        y="56.29"
        width="13.664"
        height="5.1115"
      />
      <SelectableCase
        locationId="28"
        as="rect"
        x="222.64"
        y="56.29"
        width="13.23"
        height="5.1115"
      />
      <SelectableCase
        locationId="29"
        as="rect"
        x="235.87"
        y="56.29"
        width="6.0804"
        height="5.1115"
      />
      <SelectableCase
        locationId="30"
        as="rect"
        x="241.95"
        y="56.29"
        width="18.391"
        height="5.1115"
      />
      <SelectableCase
        locationId="36"
        as="rect"
        transform="rotate(90)"
        x="131.45"
        y="-322.7"
        width="6.6818"
        height="8.7694"
      />
      <SelectableCase
        locationId="33"
        as="path"
        d="m343.7 110.84v4.8045h-20.996v15.802h-4.4832v-20.606z"
      />
      <SelectableCase
        locationId="35"
        as="rect"
        transform="rotate(90)"
        x="110.84"
        y="-318.22"
        width="20.606"
        height="4.2862"
      />
      <SelectableCase
        locationId="34"
        as="rect"
        transform="rotate(90)"
        x="106.1"
        y="-343.7"
        width="4.7384"
        height="29.765"
      />
      <SelectableCase locationId="22" as="rect" x="120" y="127.6" width="18.375" height="5.1499" />
      <SelectableCase
        locationId="21"
        as="rect"
        x="144.36"
        y="127.6"
        width="18.525"
        height="5.1499"
      />
      <SelectableCase
        locationId="20"
        as="rect"
        x="168.88"
        y="127.6"
        width="14.853"
        height="5.1499"
      />
      <SelectableCase
        locationId="19"
        as="rect"
        x="196.41"
        y="127.6"
        width="15.335"
        height="5.1499"
      />
      <SelectableCase
        locationId="18"
        as="rect"
        x="211.74"
        y="127.6"
        width="5.9467"
        height="5.1499"
      />
      <SelectableCase
        locationId="17"
        as="rect"
        x="217.69"
        y="127.6"
        width="18.174"
        height="5.1499"
      />
      <SelectableCase
        locationId="16"
        as="rect"
        x="241.95"
        y="127.6"
        width="18.391"
        height="5.1499"
      />
      <SelectableCase
        locationId="15"
        as="rect"
        x="245.57"
        y="101.97"
        width="9.0951"
        height="7.3897"
      />
      <SelectableCase
        locationId="14"
        as="rect"
        x="245.57"
        y="79.597"
        width="9.0951"
        height="7.4931"
      />
      <SelectableCase
        locationId="11"
        as="path"
        d="m227.09 109.53h-4.4571v-20.996h-14.659v-4.4832h19.116z"
      />
      <SelectableCase
        locationId="13"
        as="rect"
        transform="scale(-1)"
        x="-207.98"
        y="-88.537"
        width="6.1987"
        height="8.7694"
      />
      <SelectableCase
        locationId="12"
        as="rect"
        transform="scale(-1)"
        x="-231.49"
        y="-109.53"
        width="4.3957"
        height="29.766"
      />
      <SelectableCase
        locationId="10"
        as="rect"
        transform="scale(-1)"
        x="-227.09"
        y="-84.054"
        width="19.116"
        height="4.2862"
      />
      <SelectableCase
        locationId="9"
        as="rect"
        x="201.49"
        y="103.83"
        width="5.5294"
        height="5.5294"
      />
      <SelectableCase
        locationId="8"
        as="rect"
        x="173.33"
        y="79.597"
        width="5.5177"
        height="5.6844"
      />
      <SelectableCase
        locationId="7"
        as="path"
        transform="translate(90.299 14.708)"
        d="m63.235 64.889h4.4571v20.996h14.659v4.4832h-19.116z"
      />
      <SelectableCase
        locationId="5"
        as="rect"
        transform="translate(90.299 14.708)"
        x="63.235"
        y="90.368"
        width="19.116"
        height="4.2862"
      />
      <SelectableCase
        locationId="4"
        as="rect"
        transform="translate(90.299 14.708)"
        x="82.352"
        y="85.885"
        width="6.1987"
        height="8.7694"
      />
      <SelectableCase
        locationId="6"
        as="rect"
        transform="translate(90.299 14.708)"
        x="58.84"
        y="64.889"
        width="4.3957"
        height="29.766"
      />
      <SelectableCase locationId="3" as="rect" x="125.06" y="101.97" width="4" height="7.3897" />
      <SelectableCase locationId="2" as="rect" x="129.06" y="101.97" width="4" height="7.3897" />
      <SelectableCase locationId="1" as="rect" x="129.06" y="79.597" width="4" height="7.4931" />
      <SelectableCase locationId="0" as="rect" x="125.06" y="79.597" width="4" height="7.4931" />
      <SelectableCase
        locationId="37"
        as="rect"
        x="338.27"
        y="132.07"
        width="5.4239"
        height="6.1237"
      />
      <SelectableCase
        locationId="38"
        as="rect"
        x="313.96"
        y="156.54"
        width="5.8539"
        height="5.9945"
      />
      <SelectableCase
        locationId="41"
        as="rect"
        y="313.93353"
        x="-188.39828"
        height="29.765434"
        width="4.738369"
        transform="rotate(-90)"
      />
      <SelectableCase
        locationId="39"
        as="path"
        d="m 313.93354,183.65994 v -4.80447 h 20.99605 V 163.0538 h 4.48321 v 20.60614 z"
      />
      <SelectableCase
        locationId="40"
        as="rect"
        y="339.41278"
        x="-183.65994"
        height="4.2861714"
        width="20.606144"
        transform="rotate(-90)"
      />
      <SelectableCase
        locationId="42"
        as="rect"
        width="6.6818099"
        height="8.7693834"
        x="-163.05382"
        y="334.92957"
        transform="rotate(-90)"
      />
      <SelectableCase
        locationId="43"
        as="rect"
        x="313.96"
        y="203.87"
        width="29.733"
        height="8.9434"
      />
      <SelectableCase
        locationId="44"
        as="rect"
        x="313.96"
        y="228.43"
        width="29.733"
        height="8.7932"
      />
      <SelectableCase
        locationId="51"
        as="rect"
        x="360.73"
        y="78.413"
        width="8.989"
        height="29.379"
      />
      <SelectableCase
        locationId="52"
        as="rect"
        x="360.73"
        y="107.79"
        width="5.0426"
        height="5.9892"
      />
      <SelectableCase
        locationId="53"
        as="rect"
        x="360.73"
        y="113.78"
        width="8.989"
        height="18.293"
      />
      <SelectableCase
        locationId="54"
        as="rect"
        x="360.73"
        y="132.07"
        width="5.0426"
        height="6.1237"
      />
      <SelectableCase
        locationId="55"
        as="rect"
        x="360.73"
        y="138.2"
        width="8.989"
        height="18.345"
      />
      <SelectableCase
        locationId="56"
        as="rect"
        x="360.73"
        y="156.54"
        width="5.0426"
        height="5.9945"
      />
      <SelectableCase
        locationId="57"
        as="rect"
        x="360.73"
        y="162.54"
        width="8.989"
        height="18.345"
      />
      <SelectableCase
        locationId="58"
        as="rect"
        x="360.73"
        y="180.88"
        width="5.0426"
        height="6.4596"
      />
      <SelectableCase
        locationId="59"
        as="rect"
        x="360.73"
        y="187.34"
        width="8.989"
        height="17.854"
      />
      <SelectableCase
        locationId="60"
        as="rect"
        x="360.73"
        y="211.35"
        width="8.989"
        height="9"
      />
      <SelectableCase
        locationId="61"
        as="rect"
        x="360.73"
        y="220.35"
        width="8.989"
        height="9.3"
      />
      <SelectableCase
        locationId="62"
        as="rect"
        x="360.73"
        y="229.64"
        width="5.0426"
        height="6.072"
      />
      <SelectableCase
        locationId="63"
        as="rect"
        x="360.73"
        y="235.71"
        width="6.224"
        height="19.508"
      />
      <SelectableCase locationId="66" as="rect" x="292" y="267" width="73.6" height="6" />
      <SelectableCase locationId="32" as="rect" x="278" y="107" width="5" height="20" />
      <SelectableCase locationId="31" as="rect" x="278" y="62" width="5" height="20" />
      <SelectableCase locationId="67" as="rect" x="290.5" y="62" width="5" height="20" />
      <SelectableCase locationId="64" as="rect" x="287.5" y="84" width="5" height="20" />
      <SelectableCase locationId="68" as="rect" x="314" y="56" width="30" height="5" />
      <SelectableCase locationId="65" as="rect" x="328" y="76" width="12" height="8" />
      <SelectableCase
        locationId="45"
        as="rect"
        x="291.67"
        y="211.35"
        width="4.9632"
        height="18.293"
      />
      <SelectableCase
        locationId="46"
        as="rect"
        x="291.67"
        y="187.14"
        width="4.9632"
        height="18.061"
      />
      <SelectableCase
        locationId="47"
        as="rect"
        x="291.67"
        y="156.54"
        width="4.9632"
        height="5.9945"
      />
      <SelectableCase
        locationId="48"
        as="rect"
        x="291.67"
        y="138.2"
        width="4.9632"
        height="18.345"
      />
      <SelectableCase
        locationId="49"
        as="rect"
        x="291.67"
        y="132.75"
        width="4.9632"
        height="5.4526"
      />
      <SelectableCase
        locationId="50"
        as="rect"
        x="291.67"
        y="113.78"
        width="4.9632"
        height="18.965"
      />
    </g>
  );
};

Cases.defaultProps = {
  selectedLocationId: null,
};

Cases.propTypes = {
  selectedLocationId: PropTypes.string,
  onHoverLocation: PropTypes.func.isRequired,
  onHoverLocationEnd: PropTypes.func.isRequired,
  onClickLocation: PropTypes.func.isRequired,
};

export default Cases;
