import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';

const CardBody = ({ className, ...props }) => (
  <div className={`${styles.body} ${className}`} {...props} />
);

CardBody.defaultProps = {
  className: '',
};

CardBody.propTypes = {
  className: PropTypes.string,
};

export default CardBody;
