import React from 'react';
import SVG from '../SVG';
import styles from './styles.module.css';

const CancelIcon = ({ className = '', ...props }) => (
  <SVG className={`${styles.icon} ${className}`} viewBox="0 0 132.29166 132.29167" {...props}>
    <g transform="translate(0 -164.71)" stroke="#999" strokeWidth="15.875">
      <path d="m5.2917 170 121.71 121.71" />
      <path d="m127 170-121.71 121.71" />
    </g>
  </SVG>
);

export default CancelIcon;
