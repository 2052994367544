/* eslint react/forbid-prop-types: 0 */

import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';
import Card from '../../../elements/Card';
import CardTitle from '../../../elements/CardTitle';
import CardBody from '../../../elements/CardBody';
import CardFooter from '../../../elements/CardFooter';
import CardLink from '../CardLink';
import CardImage from '../CardImage';
import CardTags from '../CardTags';
import styles from './styles.module.css';

const capitalize = str => str.charAt(0).toUpperCase() + str.slice(1);

const ObjectCard = ({ object, imagePlaceholder }) => {
  const imageSizes = object.fields && object.fields.main_image
    ? object.fields.main_image.childImageSharp.sizes
    : imagePlaceholder;

  const title = capitalize(object.title || object.type);
  const gatewayStyle = object.tags && object.tags[0] === 'Gateway object' ? styles.gateway : null;

  return (
    <Card>
      <Link to={`/collection/${object.prn}/`}>
        <CardImage sizes={imageSizes} />
      </Link>
      <CardBody>
        {title && <CardTitle>{title}</CardTitle>}
        {object.tags && <CardTags className={gatewayStyle} tags={object.tags} />}
      </CardBody>
      <CardFooter>
        <CardLink to={`/collection/${object.prn}/`} text="Read more" />
      </CardFooter>
    </Card>
  );
};

ObjectCard.propTypes = {
  object: PropTypes.shape({
    prn: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.string,
    short_date: PropTypes.string,
    short_place: PropTypes.string,
    short_mat: PropTypes.string,
    short_desc: PropTypes.string,
  }).isRequired,
  imagePlaceholder: PropTypes.object.isRequired,
};

export default ObjectCard;

export const ObjectSummaryFragment = graphql`
  fragment ObjectSummaryFragment on ObjectsJsonConnection {
    objects: edges {
      object: node {
        prn
        title
        type
        tags
        location
        fields {
          main_image {
            ...CardImageSizesFragment
          }
        }
      }
    }
  }
`;
