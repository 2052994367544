import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';

const CardFooter = ({ className, ...props }) => (
  <div className={`${styles.footer} ${className}`} {...props} />
);

CardFooter.defaultProps = {
  className: '',
};

CardFooter.propTypes = {
  className: PropTypes.string,
};

export default CardFooter;
