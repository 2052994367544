import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';

const LabelText = ({
  className, title, children = null, ...props
}) => (
  <div className={`${styles.labelText} ${className}`} {...props}>
    <div>
      <h3>{title}</h3>
      {children && <div className={styles.body}>{children}</div>}
    </div>
  </div>
);

LabelText.defaultProps = {
  className: '',
  children: null,
};

LabelText.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default LabelText;
