import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';
import InternalLink from '../../../elements/Link/InternalLink';
import Button from '../../../elements/Button';
import ButtonArrowIcon from '../../../elements/Icons/ButtonArrowIcon';

const CardLink = ({ text, ...props }) => (
  <InternalLink {...props}>
    <Button as="span" className={styles.cardLink}>
      <span>{text}</span>
      <ButtonArrowIcon className={styles.icon} />
    </Button>
  </InternalLink>
);

CardLink.propTypes = {
  text: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

export default CardLink;
