import React from 'react';
import PropTypes from 'prop-types';
import LabelText from '../LabelText';
import cases from '../../../../data/cases/cases.json';

const PanelText = ({ selectedLocationId = null }) => {
  if (!selectedLocationId) {
    return (
      <LabelText title="Introduction to the gallery">
        The gallery comprises two rooms presenting the cultures of the Islamic world from about 600
        to the present day and spanning West Africa to Southeast Asia It highlights both the
        diversity of peoples and their traditions, and the connections between them. Gallery 42
        covers the period up to 1500 through chronological and thematic displays featuring topics
        such as global trade and exchange and luxury arts. Gallery 43 covers the period from 1500
        the present day. It features courtly culture, textiles and jewellery, arts of the book and
        contemporary art. These objects reflect the strengths of the Museum’s wide-ranging
        collection.
      </LabelText>
    );
  }

  const { title, panelText } = cases.find(c => c.id === selectedLocationId);
  return <LabelText title={title}>{panelText}</LabelText>;
};

PanelText.defaultProps = {
  selectedLocationId: null,
};

PanelText.propTypes = {
  selectedLocationId: PropTypes.string,
};

export default PanelText;
