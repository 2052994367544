/* eslint react/no-array-index-key: 0 */

import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';
import Tag from '../../../elements/Tag';

const CardTags = ({ tags, className, ...props }) => (tags ? (
  <div className={`${styles.tags} ${className}`} {...props}>
    {tags.map((t, i) => (
      <Tag key={i}>{t}</Tag>
    ))}
  </div>
) : null);

CardTags.defaultProps = {
  className: '',
};

CardTags.propTypes = {
  className: PropTypes.string,
};

export default CardTags;
