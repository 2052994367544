import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';

const Select = ({ children, className, ...props }) => (
  <select className={`${styles.select} ${className}`} {...props}>
    {children}
  </select>
);

Select.defaultProps = {
  className: '',
};

Select.propTypes = {
  className: PropTypes.string,
};

export default Select;
