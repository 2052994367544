import React from 'react';
import SVG from '../SVG';
import styles from './styles.module.css';

const ChevronDownIcon = ({ className = '', ...props }) => (
  <SVG className={`${styles.icon} ${className}`} viewBox="-634 473 16 10" {...props}>
    <path d="M-626,479.8l-6.4-6.4l-1.4,1.4l6.4,6.4l1.4,1.4l1.4-1.4l6.4-6.4l-1.4-1.4" />
  </SVG>
);

export default ChevronDownIcon;
