import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import PageTitle from '../elements/PageTitle';
import PageHeader from '../elements/PageHeader';
import Container from '../components/core/Container';
import BackLink from '../components/core/BackLink';
import SearchResultsContainer from '../containers/SearchResultsContainer';
import FreeTextSearchContainer from '../containers/FreeTextSearchContainer';
import LocationFilterContainer from '../containers/LocationFilterContainer';
import PanelTextContainer from '../containers/PanelTextContainer';
import Layout from '../components/core/Layout';

const Collection = ({ data }) => {
  const allObjects = data.allObjectsJson.objects.map(obj => obj.object);
  const imagePlaceholder = data.imagePlaceholder.edges[0].node.childImageSharp.sizes;

  return (
    <Layout>
      <Container>
        <PageHeader>
          <div>
            <PageTitle>Explore the objects in the gallery</PageTitle>
            <BackLink text="Back" />
          </div>
          <FreeTextSearchContainer />
        </PageHeader>
        <LocationFilterContainer />
        <PanelTextContainer />
        <SearchResultsContainer allObjects={allObjects} imagePlaceholder={imagePlaceholder} />
      </Container>
    </Layout>
  );
};

Collection.propTypes = {
  data: PropTypes.shape({
    allObjectsJson: PropTypes.object.isRequired,
    imagePlaceholder: PropTypes.object.isRequired,
  }).isRequired,
};

export default Collection;

export const query = graphql`
  query ObjectCards {
    imagePlaceholder: allFile(filter: { name: { eq: "missing-image" } }) {
      edges {
        node {
          ...CardImageSizesFragment
        }
      }
    }
    allObjectsJson {
      ...ObjectSummaryFragment
    }
  }
`;
