import { connect } from 'react-redux';
import LocationFilter from '../components/collection/LocationFilter';
import setLocationId from '../state/actionCreators/setLocationId';
import showGalleryMap from '../state/actionCreators/showGalleryMap';
import hideGalleryMap from '../state/actionCreators/hideGalleryMap';
import resetFilter from '../state/actionCreators/resetFilter';

const mapStateToProps = state => ({
  selectedLocationId: state.search.selectedLocationId,
  showMap: state.search.showMap,
});

const mapDispatchToProps = dispatch => ({
  setLocationId: id => dispatch(setLocationId(id)),
  showGalleryMap: () => dispatch(showGalleryMap()),
  hideGalleryMap: () => dispatch(hideGalleryMap()),
  resetFilter: () => dispatch(resetFilter()),
});

const LocationFilterContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(LocationFilter);
export default LocationFilterContainer;
