import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';

const Tag = ({ className, ...props }) => (
  <span className={`${styles.tag} ${className}`} {...props} />
);

Tag.defaultProps = {
  className: '',
};

Tag.propTypes = {
  className: PropTypes.string,
};

export default Tag;
