import lunr from 'lunr';
import { createSelector } from 'reselect';
import idx from '../../../data/miplus/idx.json';

const index = lunr.Index.load(idx);

const getCollection = state => state.search.collection;
const getQueryText = state => state.search.queryText;
const getSelectedLocationId = state => state.search.selectedLocationId;

const sortScore = object => (object.tags && object.tags[0] === 'Gateway object' ? 0 : 1);

const getSearchResults = createSelector(
  [getCollection, getQueryText],
  (collection, queryText) => (queryText
    ? index
      .query((q) => {
        const terms = queryText
          .toLowerCase()
          .split(' ')
          .filter(Boolean);
        terms.forEach((term) => {
          q.term(term);
        });

        const lastTerm = terms[terms.length - 1];

        q.term(lastTerm, {
          wildcard: lunr.Query.wildcard.TRAILING,
        });

        return q;
      })
      .map(r => collection.find(obj => obj.prn === r.ref))
      .sort((a, b) => sortScore(a) - sortScore(b))
    : collection),
);

export default createSelector(
  [getSearchResults, getSelectedLocationId],
  (searchResults, selectedLocationId) => (selectedLocationId
    ? searchResults.filter(object => object.location === selectedLocationId)
    : searchResults),
);
