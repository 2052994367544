import React from 'react';
import PropTypes from 'prop-types';
import Select from '../../../elements/Select';

const LocationFilterDropdown = ({ cases, selectedLocationId, setLocationId }) => {
  const onSelectChange = e => setLocationId(e.target.value);

  const optionGroupFor = gallery => (
    <optgroup label={`Room ${gallery}`}>
      {cases.filter(c => c.gallery === gallery).map(c => (
        <option key={c.id} value={c.id}>
          {c.case ? `Case ${c.case}: ${c.title}` : `${c.title}`}
        </option>
      ))}
    </optgroup>
  );

  return (
    <Select onChange={onSelectChange} value={selectedLocationId}>
      <option disabled value="">
        Filter by display case
      </option>
      {optionGroupFor('42')}
      {optionGroupFor('43')}
    </Select>
  );
};

LocationFilterDropdown.defaultProps = {
  selectedLocationId: null,
};

LocationFilterDropdown.propTypes = {
  selectedLocationId: PropTypes.string,
  cases: PropTypes.arrayOf(PropTypes.object).isRequired,
  setLocationId: PropTypes.func.isRequired,
};

export default LocationFilterDropdown;
