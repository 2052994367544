import { connect } from 'react-redux';
import SearchResults from '../components/collection/SearchResults';
import setCollection from '../state/actionCreators/setCollection';
import showMoreResults from '../state/actionCreators/showMoreResults';
import getFilteredSearchResults from '../state/selectors/getSearchResults';

const mapStateToProps = (state) => {
  const { queryText, resultsLimit } = state.search;
  return {
    results: getFilteredSearchResults(state),
    queryText,
    limit: resultsLimit,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  setCollection: () => dispatch(setCollection(ownProps.allObjects)),
  onShowMore: () => dispatch(showMoreResults()),
});

const SearchResultsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchResults);
export default SearchResultsContainer;
