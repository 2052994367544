import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';

const SearchResultsMessage = ({
  totalResults, totalShowing, className, ...props
}) => (totalResults > 0 ? (
  <div className={`${styles.message} ${className}`} {...props}>
    {`Showing results 1 - ${
      totalResults < totalShowing ? totalResults : totalShowing
    } of ${totalResults}`}
  </div>
) : null);

SearchResultsMessage.defaultProps = {
  className: '',
};

SearchResultsMessage.propTypes = {
  className: PropTypes.string,
  totalResults: PropTypes.number.isRequired,
  totalShowing: PropTypes.number.isRequired,
};

export default SearchResultsMessage;
