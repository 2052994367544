import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';

const Card = ({ children, className, ...props }) => (
  <div className={`${styles.card} ${className}`} {...props}>
    <div className={styles.cardInner}>{children}</div>
  </div>
);

Card.defaultProps = {
  className: '',
};

Card.propTypes = {
  className: PropTypes.string,
};

export default Card;
