import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';

const CardRow = ({ children }) => <div className={styles.cardRow}>{children}</div>;

CardRow.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CardRow;
